import { Injectable, inject } from '@angular/core';
import * as packageJson from '../../../../../package.json';
import { ConfigService } from './config.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    // injections
    configService = inject(ConfigService);
    popoverConfigService = inject(NgbPopoverConfig);

    init() {
        this.popoverConfigService.animation = false;
        addEventListener('scroll', (event) => this.handleToastScroll(event));
    }

    getVersion(): string {
        if (['development', 'staging', 'acceptance'].includes(<string>this.configService.serverConfig().nodeEnv)) {
            return `${this.configService.serverConfig().nodeEnv} ${packageJson.version} - ${
                this.configService.serverConfig().uptime
            }`;
        } else return '';
    }

    getVersionNumber() {
        return packageJson.version;
    }

    handleToastScroll(event) {
        if (window.scrollY >= 60) {
            document.querySelector('body').classList.add('toasts--sticky');
        } else {
            document.querySelector('body').classList.remove('toasts--sticky');
        }
    }
}
